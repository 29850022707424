<template>
  <div id="allergens">
    <v-app-bar app>
      <v-toolbar-title>{{
        $t('errors.error429.toolbarTitle')
      }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-alert
          :value="seconds > 0"
          type="error"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
        >
          <p class="subtitle-1 font-weight-medium">
            {{ $t('errors.error429.title') }}
          </p>
          <div class="body-2">
            {{ $t('errors.error429.description1') }}
            <br />
            <strong>{{ time }}</strong>
            {{ $t('errors.error429.description2') }}
          </div>
        </v-alert>

        <v-alert
          :value="seconds == 0"
          type="success"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
        >
          <p class="subtitle-1 font-weight-medium">
            {{ $t('errors.error429.rescan') }}
          </p>
          <div class="body-2">{{ $t('errors.error429.rescanDetails') }}</div>
        </v-alert>

        <v-card>
          <v-row align="center" justify="center">
            <v-col>
              <v-img
                :src="timeoutImage()"
                aspect-ratio="1"
                class="grey lighten-2"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { MEDIA_BASE } from '../../constants';
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    seconds: 300,
    interval: null,
  }),

  computed: {
    ...mapGetters('restaurant', {
      restaurant: 'restaurant',
    }),
    ...mapGetters('security', {
      scanError: 'scanError',
    }),

    time() {
      const m = Math.floor(this.seconds / 60);
      const s = this.seconds % 60;
      return (
        [m > 9 ? m : '0' + m || '0', s > 9 ? s : '0' + s]
          // .filter(a => a)
          .join(':')
      );
    },
  },

  mounted() {
    // Get the time error
    if (this.scanError) {
      this.seconds = parseInt(this.scanError.response.headers['retry-after']);
    }

    this.interval = setInterval(() => {
      this.seconds--;
      if (this.seconds <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    timeoutImage() {
      return MEDIA_BASE + '/images/440-login-time-out.jpg';
    },
  },
};
</script>

<style></style>
