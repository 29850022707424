<template>
  <div id="allergens">
    <v-app-bar app>
      <v-toolbar-title>{{
        $t('errors.error440.toolbarTitle')
      }}</v-toolbar-title>
      <v-progress-linear
        :value="percent"
        absolute
        bottom
        color="primary accent-4"
      ></v-progress-linear>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-alert
          type="info"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
        >
          {{ $t('errors.error440.sessionExpired') }}
          <br />
          <strong>{{ seconds }}</strong>
          {{ $t('errors.error440.details') }}
        </v-alert>

        <v-card>
          <v-row align="center" justify="center">
            <v-col>
              <v-img
                :src="timeoutImage()"
                aspect-ratio="1"
                class="grey lighten-2"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { MEDIA_BASE } from '../../constants';

export default {
  data: () => ({
    progress: 0,
    interval: 0,
  }),

  computed: {
    percent() {
      // 60 000 => 100
      // 15 000 => 100
      // 00 150 =>   1
      return this.progress / 12;
    },
    seconds() {
      return 60 - Math.round(this.percent * 0.6);
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      if (this.progress === 1200) {
        clearInterval(this.interval);
        // FIXME: Change URL with easy serve or restaurant url
        window.location.href = 'https://www.google.com/';
        return;
      }

      this.progress++;
    }, 50);
  },

  methods: {
    timeoutImage() {
      return MEDIA_BASE + '/images/440-login-time-out.jpg';
    },
  },
};
</script>

<style></style>
