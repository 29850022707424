<template>
  <div id="allergens">
    <v-app-bar app>
      <v-toolbar-title>{{
        $t('errors.error404.toolbarTitle')
      }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-alert
          type="error"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
        >
          <p class="subtitle-1 font-weight-medium">
            {{ $t('errors.error404.title') }}
          </p>
          <div class="body-2">{{ $t('errors.error404.details') }}</div>
        </v-alert>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({}),

  computed: {
    ...mapGetters('restaurant', {
      restaurant: 'restaurant',
    }),
  },
};
</script>

<style></style>
