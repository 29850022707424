<template>
  <div id="allergens">
    <v-app-bar app>
      <v-toolbar-title>{{
        $t('errors.error401.toolbarTitle')
      }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-alert
          type="error"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
        >
          <p class="subtitle-1 font-weight-medium">
            {{ $t('errors.error401.title') }}
          </p>
          <div class="body-2">{{ $t('errors.error401.details') }}</div>
        </v-alert>

        <v-card>
          <v-row align="center" justify="center">
            <v-col>
              <v-img
                :src="timeoutImage()"
                aspect-ratio="1"
                class="grey lighten-2"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { MEDIA_BASE } from '../../constants';
import { mapGetters } from 'vuex';
export default {
  data: () => ({}),

  computed: {
    ...mapGetters('restaurant', {
      restaurant: 'restaurant',
    }),
  },

  methods: {
    timeoutImage() {
      return MEDIA_BASE + '/images/440-login-time-out.jpg';
    },
  },
};
</script>

<style></style>
